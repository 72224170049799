<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>

    <a class="logo" href="#" (click)="navigateHome()">
      <img src="https://app.kuubs.nl/images/logo_kuubs_mail.png">
    </a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.firstname+' '+user?.lastname">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
