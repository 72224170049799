import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  model: string;
  base = environment.apiBaseUrl;
  _vault = localStorage;

  constructor(public _http: HttpClient) {

  }

  public setModel(model) {
    this.model = model;
  }

  public getHeaders() {
    return new HttpHeaders().set('Accept', 'application/json').set('Authorization', localStorage.getItem('token'));
  }

  getAll(filter?: Object): Observable<any[]> {
    return this._http
      .get<any[]>(`${this.base}/${this.model}?filter=${JSON.stringify(filter)}`, {headers: this.getHeaders()});
  }

  get(id: string, filter?: Object): Observable<any> {
    return this._http
      .get<any>(`${this.base}/${this.model}/${id}${(filter ? `?filter=${JSON.stringify(filter)}` : '')}`,
        {headers: this.getHeaders()});
  }

  insert(object: any): Observable<any> {
    return this._http.post<any>(`${this.base}/${this.model}/`, object, {headers: this.getHeaders()})
  }

  update(id: string, object: any): Observable<any> {
    return this._http.patch<any>(`${this.base}/${this.model}/${id}`, object, {headers: this.getHeaders()});
  }

  delete(id: string): Observable<any> {
    return this._http.delete(`${this.base}/${this.model}/${id}`, {headers: this.getHeaders()});
  }

  count(filter: Object): Observable<any> {
    return this._http.get(
      `${this.base}/${this.model}/count?where=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()}
    );
  }

  custom(path: string, id?: string, object?: any, context?: string): Observable<any> {
    return this._http.post<any>(`${this.base}/${this.model}/${(id ? id + '/' : '')}${path}`, object, {headers: this.getHeaders()});
  }

  public upload(id: string, object: any) {
    const uploadURL = `${this.base}/${this.model}/${id}/upload`;
    return this._http.post<any>(uploadURL, object, {headers: this.getHeaders()});
  }
}
