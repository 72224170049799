import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class LocaleService {
  localeStr: string;

  constructor() {
  }

  setLocale(value: string) {
    this.localeStr = value;
  }

  getLocale(): string {
    this.localeStr = 'nl';
    return this.localeStr;
  }

  public registerLang(lang: string) {
    if (!lang) {
      return;
    }
    switch (lang) {
      case 'nl-NL': {
        this.localeStr = 'nl';
        break;
      }
    }
  }
}
