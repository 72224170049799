import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {TranslateLoader} from '@ngx-translate/core';
import {of} from "rxjs";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class TranslationService implements TranslateLoader {

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    console.log('getTranslation');
    return of('[]');
    // return new TranslateHttpLoader(this.http, './assets/i18n/', '.json').subscribe((result) => {
    //   return of('[]');
    // });
  }
}
