<nb-card>
  <nb-card-header>{{ data.record['firstName'] }}</nb-card-header>
  <nb-card-body *ngIf="this.fields">
    <div *ngFor="let field of this.fields; let index = index" class="field-pane">
      <div class="field">
        <strong>{{field}}</strong>
        {{data.record[field]}}
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="dismiss()">Sluiten</button>
  </nb-card-footer>
</nb-card>
