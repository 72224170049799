import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {NbLoginComponent, NbAuthService, NB_AUTH_OPTIONS, NbAuthResult, getDeepFromObject} from '@nebular/auth';
import {Router} from "@angular/router";


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [NbLoginComponent, NbAuthService]
})
export class LoginComponent implements OnInit {
  redirectDelay = 0;
  showMessages: any = {};
  strategy = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted = false;
  rememberMe = false;

  constructor(protected service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected router: Router) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;

      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
      }

      const redirect = result.getRedirect();
      const userData = result.getToken();
      localStorage.setItem('user_id', userData.getPayload().user_id);
      localStorage.setItem('token', userData.getValue());
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
      this.cd.detectChanges();
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  ngOnInit(): void {
    console.log('xx');
  };
}
