import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";

@Component({
  selector: 'ngx-global-document-view',
  templateUrl: './global-document-view.component.html',
  styleUrls: ['./global-document-view.component.scss']
})
export class GlobalDocumentViewComponent implements OnInit {
  @Input() data: any;
  fields: any[] = [];
  constructor(protected ref: NbDialogRef<GlobalDocumentViewComponent>) {

  }

  ngOnInit() {
    this.fields = Object.keys(this.data.record);
  }

  dismiss() {
    this.ref.close();
  }
}
