import {Component} from '@angular/core';
import {NbAuthService, NbResetPasswordComponent} from "@nebular/auth";

@Component({
  selector: 'ngx-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
  providers: [NbResetPasswordComponent, NbAuthService]
})
export class RequestPasswordComponent {

}
