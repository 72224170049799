import { Component } from '@angular/core';
import {NbAuthService, NbRequestPasswordComponent} from "@nebular/auth";

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [NbRequestPasswordComponent, NbAuthService]

})
export class ResetPasswordComponent {

}
