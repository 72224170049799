import {Component, Inject, Input} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";

@Component({
  selector: 'ngx-global-confirm-dialog',
  templateUrl: './global-confirm-dialog.component.html',
  styleUrls: ['./global-confirm-dialog.component.scss']
})
export class GlobalConfirmDialogComponent {
  @Input() title: string;
  @Input() message: string;
  constructor(
    protected ref: NbDialogRef<GlobalConfirmDialogComponent>
  ) {}

  cancel() {
    this.ref.close();
  }

  accept (accept) {
    this.ref.close(accept);
  }
}
