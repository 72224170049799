import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {User} from '../models/user';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalService} from './global.service';

@Injectable()
export class UserService extends GlobalService {
  constructor(_http: HttpClient) {
    super(_http);
    this.setModel('users');
  }
}
